body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  /*padding-top: 15%;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root, .App, .container {
  min-height: 100%;
}

.content {
  overflow: hidden;
}

footer {
  padding: 1rem;
}

.intro-audiocament {
  background-color: #307C5E;
  padding: 3rem;
  color: #ffffff;
  min-height: 100%;
}

.btn-validate {
  color: #ffffff;
  background-color: #307C5E;
}

.intro-audiocament h1 {
  margin-top: 4rem;
  font-size: 2rem;
  text-align: center;
}

.intro-text {
  padding: 1rem;
  text-align: justify;
}

.form-page {
  padding: 2rem;
}

.downloadCards .card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subHeader {
  text-align: center;
}

.form-page {
  position: relative;
  min-height: 100%;
}

.form-page > div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.form-group.button-container {
  padding: 1rem;
  text-align: center;
}

.downloadCards {
  padding-top: 3rem;
}

.logo-container {
  width: 100%;
  text-align: center;
}

.logo-container img {
  max-width: 70%;
}

@media screen and (min-width: 576px) {

  .content {
    max-width: 90%;
    margin: auto;
  }

  .centered {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
  }

  footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .App {
    position: relative;
  }

}